import functions from "./functions.js";

$(function () {

    const $form = $('.booking-add-guests');
    const form = $form[0];

    if (!form) {
        return;
    }

    const bookingAddGuests = {
        $form: null,
        $payment: null,
        $calculation: null,
        calc: [],
        persons: null,
        promoDiscount: null,
        totalAmount: null,
        totalAmountConverted: null,
        insurance: null,
        status: null,

        init() {

            $("#customerIsPassenger").on('change', e => {
                let $passenger = $('.passenger:first').closest('.row');
                $passenger.find('[name="firstName0"]').val($('.customer[name="firstName"]').val());
                $passenger.find('[name="lastName0"]').val($('.customer[name="lastName"]').val());
                $passenger.find('[name="email0"]').val($('.customer[name="email"]').val());
                $passenger.find('[name="phone0"]').val($('.customer[name="phone"]').val());
                $passenger.find('[name="address0"]').val($('.customer[name="address"]').val());
                $passenger.find('[name="city0"]').val($('.customer[name="city"]').val());
            });

            $form.on('submit', e => {
                e.preventDefault();
                bookingAddGuests.submit();
            });


           /* $(".passenger").each(function( index ) {
                let $input = $(this).attr('name');
                let name = $input.replace(/[0-9]/g, '');

                if (name == 'firstName') $(this).val('katarina');
                if (name == 'lastName') $(this).val('test');
                if (name == 'birthDateDay') $(this).find('option[value="01"]').attr('selected', 'selected').trigger("change");
                if (name == 'birthDateMonth') $(this).find('option[value="02"]').attr('selected', 'selected').trigger("change");
                if (name == 'birthDateYear') $(this).find('option[value="1987"]').attr('selected', 'selected').trigger("change");
                if (name == 'documentNo') $(this).val('19874156445');
                if (name == 'email') $(this).val('info@neolab.hr');
                if (name == 'phone') $(this).val('address0');
                if (name == 'address') $(this).val('pula test bb');
                if (name == 'city') $(this).val('pula test bb');

            });*/

        },
        submit() {

            let data = {
                customer: {},
                passengers: []
            };
            $(".customer").each(function( index ) {
                data.customer[$(this).attr('name')] = $(this).val();
            });

            $(".passenger").each(function( index ) {
                let $input = $(this).attr('name');
                let idx = $input.replace(/\D/g,'');
                let name = $input.replace(/[0-9]/g, '');

                if ( ! data.passengers[idx]) data.passengers[idx] = {};
                data.passengers[idx][name] = $(this).val();
            });

            data.rId = $("[name='rId']").val();
            data.rhid = $("[name='rhid']").val();

            if ($("[name='agreeContact']").attr('checked')) {
                data.note = 'Putnici se slažu da se kontakt podaci koriste za marketinške svrhe';
            }

            $.post('/services/booking_add_guests/', data).then(response => {

                response = JSON.parse(response);

                $('#bookingAddGuests').modal('show');
                $(".response-output-success").toggleClass('d-none', !response.status);
                $(".response-output-error").toggleClass('d-none', response.status);

                $('.contact-btn').removeClass('d-none');
                $('.contact-spinner').removeClass('d-flex');
                $('.contact-spinner').addClass('d-none');

                setTimeout(() => {
                    $('#bookingAddGuests').modal('hide');
                    // details contact modal
                    $('#bookingAddGuests').modal('hide');
                }, 4000);

                if (response.status == true) {
                    form.reset();
                }
            });

        },
    }

    bookingAddGuests.init();
});