import functions from '../functions.js';
import { details } from './details';
import { DetailsCart } from './details-cart.js';

$(() => {
    detailsAccommodation.init();
})

const detailsAccommodation = {
    $details: null,
    $form: null,
    form: null,
    $cart: null,
    cart: [],
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    defaults: {
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: 1,
        optionals: 'base',
    },
    init() {

        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');
        
        if(! this.$details[0] || this.params.objectGroupId != 1) {
            return;
        }
        if(+this.$details.attr('data-dynamicpackage') != 0) {
            return;
        }

        this.$form = $('.availability-form');
        this.form = this.$form[0];

        this.params.objectId = this.$details.attr('data-objectid');
        this.params.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;
        this.params.isBrosTravel = $('[data-isBrosTravel]').length ? $('[data-isBrosTravel]').attr('data-isBrosTravel') : null;
        this.params.isBrosLive = $('[data-isBrosLive]').length ? $('[data-isBrosLive]').attr('data-isBrosLive') : null;

        this.getParams();
        if(! this.params.dates && this.params.dateFrom && this.params.dateTo) {
            this.params.dates = this.params.dateFrom + ' - ' + this.params.dateTo;
        }
        
        if(this.form) {
            functions.setFormData(this.form, this.params);
        }

        this.params.adultsOnly = $('.input-guests').attr('data-adultsonly');

        if(this.params.guests) {

            this.params.guests = this.params.adultsOnly ? this.params.guests.split(',')[0] + ',0' : this.params.guests;

            $('[name=guests]').each((index, elem) => {
                $(elem).val(this.params.guests);

                let guests = this.params.guests.split(',');
                let i = 1;
                for (let index = 2; index < guests.length; index++) {
                    if(guests[index]) {
                        $('[name=child-age-'  + i + ']').val(guests[index]);
                    }
                    i++;
                }
            });
        }

        if(! this.params.serviceCode && $('[name="serviceCode"]').length) {
            this.params.serviceCode = $('[name="serviceCode"]').val();
        } 

        this.getCalculation();

        this.$form.on('submit', e => {
            e.preventDefault();
            let params = functions.getFormData($(e.currentTarget));
            this.params.rateId = null;
            this.params.unitId = null;
            functions.setUrlData(params, true, true);
        });

        // check availability on form change (not only on btn click)
        $('[name="dates"], [name=guests], [name="pricelistId"], [name="serviceCode"], [name="optionals"]').on('change', () => {
            const data = functions.getFormData(this.$form);
            if ( data.dates && (data.dates.split(' - ')[1] || false) && data.guests ) {
                this.$form.trigger('submit');
            }
        });

        this.$cart = $('.details-cart');
        this.cart = this.$cart.length ? new DetailsCart(this.params) : [];

        $(window).on('hashchange', e => {
            this.getParams();
            this.params.guests = this.params.adultsOnly ? this.params.guests.split(',')[0] + ',0' : this.params.guests;
            this.getCalculation(this.params);
        });

        if(this.$cart.length) {
            $('.btn-booking').on('click', e => {
                e.preventDefault();
    
                if(! this.cart.cartItems.length) {
                    $('#detailsCartModal').modal('show');
                    setTimeout(() => {
                        $('#detailsCartModal').modal('hide');
                    }, 2500);
                }
            });
        }

    },
    printDates() {
        $('.unit-departure').addClass('fade');
        setTimeout(() => {
            $('.unit-departure').removeClass('fade');
            $('.date-from').text(functions.formatDate(this.params.dateFrom));
            $('.date-to').text(functions.formatDate(this.params.dateTo));
            $('.overnights').text($('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']').attr('data-duration'));
        }, 800);

    },
    getParams() {
        this.params = details.getParams(this.params, this.defaults);
        this.params.guests = this.params.guests && this.params.guests.startsWith('0') ? null : this.params.guests;
        if(this.params.dates) {
            this.splitCalendarDates(this.params.dates);
        }
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    getCalculation(params) {
        const cParams = params ? params : this.params;

        $('.unit').find('.unit-price-total').html("");
        $('.unit').find('.unit-price-total-label').addClass('d-none');
        const calculation = details.getCalculation(cParams);
        
        if(calculation) {
            this.loader(cParams.unitId ? $('.unit[data-unitid=' + cParams.unitId + ']').find('.unit-spinner') : null);
            calculation
                .done(response => {
                    this.calculationDone(response, cParams);
                }).fail(error => {
                    console.log(error);
                });
        } else {
            $('.unit-price-payment-key').toggleClass('d-none', false);

            //$('.unit-price-discount-title').toggleClass('d-none', false);
            //$('.btn-booking-container').toggleClass('d-none', false);

            this.params.isBrosTravel && $('.btn-booking').toggleClass('d-none', true);
            $('.add-to-cart').toggleClass('d-none', true);

            $('.no-period-label').toggleClass('d-none', false);
            $('.availablility-label').toggleClass('d-none', true);
            $('.unit-price').toggleClass('d-none', false);

            $('.unit-error-container').toggleClass('d-none', true);
            $('.unit-price-total-label').toggleClass('d-none', true);

            const optionals = cParams.optionals ? cParams.optionals : this.defaults.optionals;
            let serviceText = '';
            if(optionals && optionals != this.defaults.optionals) {
                const allowedServices = JSON.parse($('[data-allowedservices]').attr('data-allowedservices'));
                allowedServices.forEach(item => {
                    if(item.supplementKey == optionals) {
                        serviceText = item.text;
                    }
                });
            }

            $('.unit').each((index, elem) => {
                const $elem = $(elem);

                const prices = $elem.attr('data-prices') ? JSON.parse($elem.attr('data-prices')) : null;
                const pricelistId = cParams.pricelistId ? cParams.pricelistId : $elem.attr('data-pricelistid');
                const item = prices ? (prices[pricelistId][optionals] ? prices[pricelistId][optionals] : prices[pricelistId]) : null;
                const pricelistsService = $elem.attr('data-pricelistsservice') ? JSON.parse($elem.attr('data-pricelistsservice')) : null;

                if(item) {
                    const $unitPriceTotal = $elem.find('.unit-price-total');
                    const $unitPriceOld = $elem.find('.unit-price-old');
                    const $unitPricePaymentKey = $elem.find('.unit-price-payment-key');
                    const $serviceSupplement = $elem.find('.service-supplement');
    
                    $unitPriceTotal.text(item.price != '€ 0' ? item.price : '');
                    $unitPriceOld.text(item.priceOld != '€ 0' ? item.priceOld : '');
                    $unitPriceOld.toggleClass('d-none', false);
                    $unitPricePaymentKey.text(item.paymentKey);
                    $serviceSupplement.text(pricelistsService[pricelistId] ? pricelistsService[pricelistId] : serviceText);
                }

                const order = $elem.attr('data-order');
                $elem.prop('style', 'order: ' + (order ? order : '10000'));
            })
        }
    },
    calculationDone(response, params) {

        if(response.status) {
            const data = response.data || [];

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = this.params.rateId ? $('.arrangement-form[data-unitid=' + unitId + '][data-rateid=' + this.params.rateId + ']') : $('.unit[data-unitid=' + unitId + ']');
                const $btnBooking = $unit.find('.btn-booking');

                if(details.isBookable(unitData)) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);
                    let discountTitle = details.getCalcDiscountTitle(calcItems);

                    if(discountTitle) {
                        $unit.find('.unit-price-discount-title').text(discountTitle);
                        $unit.find('.fm-discount-title').text(discountTitle ? discountTitle : '');
                    }

                    if(priceOld > total) {
                        $unit.find('.unit-price-old').text(currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ','));
                    } else {
                        $unit.find('.unit-price-old').toggleClass('d-none', true);
                    }

                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));

                    let cartData = [];

                    cartData.push({
                        'unitId': unitId,
                        'dateFrom': this.params.dateFrom,
                        'dateTo': this.params.dateTo,
                        'guests': this.params.guests,
                    }); 

                    if ($btnBooking.attr('href')) {
                        $btnBooking.attr('href',
                            $btnBooking.attr('href').split('?')[0] +
                            '?unitId=' + unitId +
                            '&dateFrom=' + this.params.dateFrom +
                            '&dateTo=' + this.params.dateTo +
                            '&guests=' + this.params.guests + '&addAllOptionals=true'
                        );
                    }

                    if(this.$cart.length) {
                        // $addToCart.attr('data-unitcarttotal', total);
                        // $addToCart.attr('data-unitcarttotalold', priceOld);
                        // $addToCart.attr('data-currencysymbol', currencySymbol);
    
                        // $addToCart.attr('data-datefrom', params.dateFrom);
                        // $addToCart.attr('data-dateto', params.dateTo);
                        // $addToCart.attr('data-guests', params.guests);

                        // params.serviceCode && $addToCart.attr('data-servicecode', params.serviceCode);
                    } else {
                        const $btnBooking = $unit.find('.btn-booking[data-unitId=' + unitId + ']');

                        $btnBooking.text(unitData.booking.status == 'Request' ? $btnBooking.attr('data-requestLabel') : $btnBooking.attr('data-bookingLabel'));

                        // $btnBooking.on('click', e => {
                        //     e.preventDefault();

                        //     let cartData = [
                        //         {
                        //             'unitId': unitId,
                        //             'dateFrom': params.dateFrom,
                        //             'dateTo': params.dateTo,
                        //             'guests': params.guests,
                        //             'pricelistId': params.pricelistId,
                        //             'serviceCode': params.serviceCode,
                        //             'optionals': params.optionals,
                        //         }
                        //     ];

                        //     location = $(e.currentTarget).attr('data-href') + '?cart_data=' + JSON.stringify(cartData);
                        // });
                    }
                    
                    //this.printDates();

                    $unit.prop('style', 'order: ' + parseInt(total));
                    
                } else {
                    $unit.prop('style', 'order: 10000');
                }

                //$unit.find('.unit-price-label').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-price').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.unit-price-discount-title').toggleClass('d-none', ! details.isBookable(unitData));
                $unit.find('.unit-price-payment-key').toggleClass('d-none', details.isBookable(unitData));

                $unit.find('.fm-discount').toggleClass('d-none', details.isBookable(unitData));
                
                $unit.find('.fm-discount-valid-until').toggleClass('d-none', details.isBookable(unitData));
                //$unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');

                //$unit.find('.no-period-label').toggleClass('d-none', details.isBookable(unitData));

                this.params.isBrosTravel && $unit.find('.btn-booking').toggleClass('d-none', ! details.isBookable(unitData));

                
                $btnBooking.length && $btnBooking.toggleClass('d-none', ! details.isBookable(unitData));

                //$unit.find('.btn-booking-container').toggleClass('d-none', ! details.isBookable(unitData));
                //$unit.find('.btn-request-nobookable').toggleClass('d-none', details.isBookable(unitData));

                let error = details.getCalcErrorMessage(this.params.calcErrorMessages, unitData);
                $unit.find('.unit-error-container').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-error-container').text(error);
                
                $unit.find('.availablility-label').toggleClass('d-none', unitData.message != 'notAvailable');

                if (this.params.isBrosLive)
                {
                    $unit.find('.no-period-label').toggleClass('d-none', true);
                    $unit.find('.bros-message').toggleClass('d-none', false);
                }
                else
                {
                    $unit.find('.no-period-label').toggleClass('d-none', true);
                }

                this.params.isBrosTravel && details.isBookable(unitData) && $unit.find('.bros-message').toggleClass('d-none', false);

                if(this.params.isBrosTravel && ! details.isBookable(unitData)) {
                    $unit.find('.availablility-label').toggleClass('d-none', true);
                    $unit.find('.bros-message').toggleClass('d-none', true);
                    $unit.find('.unit-error-container').toggleClass('d-none', false);
                    if(this.params.calcErrorMessages) {
                        $unit.find('.unit-error-container').text(this.params.calcErrorMessages.general);
                    }
                }

                $unit.find('.unit-price-label').toggleClass('d-none', true);
                params.pricelistId = params.pricelistId ? params.pricelistId : $unit.attr('data-pricelistid');
            });

            // if(this.$cart.length) {
            //     const $btnBooking = $('.btn-booking');
                
            //     $btnBooking.on('click', e => {
            //         e.preventDefault();
            //         let cartData = [];

            //         this.cart.cartItems.forEach(item => {
            //             item = JSON.parse(item);
            //             cartData.push({
            //                 'unitId': item.unitId,
            //                 'dateFrom': item.dateFrom,
            //                 'dateTo': item.dateTo,
            //                 'guests': item.guests,
            //                 'pricelistId': item.pricelistId,
            //                 'serviceCode': item.serviceCode,
            //                 'optionals': item.optionals,
            //             }); 
            //         });
            //         let url = $(e.currentTarget).attr('data-href') + '?cart_data=' + JSON.stringify(cartData) + '&addAllOptionals=true';

            //         if(this.cart.cartItems.length) {
            //             location = url;
            //         } else {
            //             $('#detailsCartModal').modal('show');
            //             setTimeout(() => {
            //                 $('#detailsCartModal').modal('hide');
            //             }, 2500);
            //         }

            //         //location = url;
            //     });
            // }
        } else {
            $(".btn-booking").addClass('d-none');
            $(".availablility-label").removeClass('d-none');

            let error = details.getCalcErrorMessage(this.params.calcErrorMessages, {});
            $('.unit-error-container').removeClass('d-none');
            $('.unit-error-container').text(error);
        }
    },
    loader($target = null) {
        $target ? $target.addClass('active') : $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $target ? $target.removeClass('active') : $('.unit-spinner').removeClass('active');
        }, 800);
    }
};
